$calendar-legacy-border: none !default;
$calendar-legacy-border-radius: $radius-small !default;
$calendar-legacy-header-background-color: $primary !default;
$calendar-legacy-days-background-color: transparent !default;
$calendar-legacy-header-days-color: $grey-light !default;
$calendar-legacy-date-color: $text !default;
$calendar-legacy-date-hover-background-color: $white-ter !default;
$calendar-legacy-today-background: transparent !default;
$calendar-legacy-today-border-color: $primary !default;
$calendar-legacy-today-color: $primary !default;
$calendar-legacy-range-background-color: lighten($primary, 50%) !default;
$calendar-legacy-body-padding: 0 1em 1em 1em !default;
$calendar-legacy-header-padding: 1em 1em 0 1em !default;
$calendar-legacy-header-nav-padding: .5em !default;
$calendar-legacy-date-padding: .4rem 0 !default;

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value}; }

@mixin calendar {
  .calendar-legacy {
    background: $white;
    border: $calendar-legacy-border;
    border-radius: $calendar-legacy-border-radius;
    display: block;
    min-width: 20rem;
    text-align: center;
    max-width: 20rem;
    &.is-active {
      display: initial; }
    .calendar-nav {
      background: $calendar-legacy-header-background-color;
      color: $white;
      border-top-left-radius: $radius-small;
      border-top-right-radius: $radius-small;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $size-5;
      padding: $calendar-legacy-header-nav-padding;
      .calendar-nav-month,
      .calendar-nav-day,
      .calendar-nav-year {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .calendar-month,
      .calendar-day,
      .calendar-year {
        flex: 1; }
      .calendar-month {
        font-size: $size-4; }
      .calendar-day {
        font-size: $size-2; }
      .calendar-nav-previous-month, .calendar-nav-next-month,
      .calendar-nav-previous-year, .calendar-nav-next-year, {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        text-decoration: none;
        color: $white;
        &:hover {
          background-color: transparent;
          svg {
            stroke-width: 1em; } }
        svg {
          stroke: currentColor;
          width: 11.25px;
          height: 18px; } } }
    .calendar-header, .calendar-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
    .calendar-header .calendar-date, .calendar-body .calendar-date {
      flex: 0 0 14.28%;
      max-width: 14.28%; }
    .calendar-header {
      padding: $calendar-legacy-header-padding;
      background: $calendar-legacy-days-background-color;
      color: findColorInvert( $calendar-legacy-days-background-color );
      font-size: $size-7;
      .calendar-date {
        color: $calendar-legacy-header-days-color; } }
    .calendar-body {
      padding: $calendar-legacy-body-padding;
      color: $grey; }
    .calendar-date {
      border: 0;
      padding: $calendar-legacy-date-padding;
      .date-item {
        appearance: none;
        background: transparent;
        border: .1rem solid transparent;
        border-radius: 100%;
        color: $calendar-legacy-date-color;
        cursor: pointer;
        height: 2.2rem;
        line-height: 1.4rem;
        outline: none;
        padding: .3rem;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: all .2s ease;
        vertical-align: middle;
        white-space: nowrap;
        width: 2.2rem;
        &.is-today {
          background: $calendar-legacy-today-background;
          border-color: $calendar-legacy-today-border-color;
          color: $calendar-legacy-today-color; }
        &:focus {
          background: $calendar-legacy-date-hover-background-color;
          border-color: $calendar-legacy-date-hover-background-color;
          color: findColorInvert( $calendar-legacy-date-hover-background-color );
          text-decoration: none; }
        &:hover {
          background: $calendar-legacy-date-hover-background-color;
          border-color: $calendar-legacy-date-hover-background-color;
          color: findColorInvert( $calendar-legacy-date-hover-background-color );
          text-decoration: none; }
        &.is-active {
          background: $primary;
          border-color: $primary;
          color: findColorInvert( $primary ); } }
      &.is-disabled,
      &[disabled] {
        .date-item, .calendar-event {
          cursor: default;
          opacity: .25;
          pointer-events: none; } } }
    .calendar-range {
      position: relative;
      &::before {
        background: $calendar-legacy-range-background-color;
        content: "";
        height: 2.2rem;
        left: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
      &.calendar-range-start::before {
        left: 50%; }
      &.calendar-range-end::before {
        right: 50%; }
      .date-item {
        color: $primary; } }

    &.is-large {
      max-width: 100%;
      .calendar-body {
        .calendar-date {
          border-bottom: $calendar-legacy-border;
          border-right: $calendar-legacy-border;
          display: flex;
          flex-direction: column;
          height: 11rem;
          padding: 0;
          &:nth-child(7n) {
            border-right: 0; }
          &:nth-last-child(-n+7) {
            border-bottom: 0; } } }
      .date-item {
        align-self: flex-end;
        height: 2.2rem;
        margin-right: .5rem;
        margin-top: .5rem; }
      .calendar-range {
        &::before {
          top: 1.9rem; }
        &.calendar-range-start::before {
          left: auto;
          width: 1.9rem; }
        &.calendar-range-end::before {
          right: 1.9rem; } }
      .calendar-events {
        flex-grow: 1;
        line-height: 1;
        overflow-y: auto;
        padding: .5rem; }
      .calendar-event {
        background-color: $grey;
        border-radius: $radius-small;
        color: $white;
        display: block;
        font-size: 1rem;
        margin: .2rem auto;
        overflow: hidden;
        padding: .3rem .4rem;
        text-align: left;
        text-overflow: ellipsis;
        vertical-align: baseline;
        white-space: nowrap;
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          $color-invert: nth($pair, 2);
          &.is-#{$name} {
            background-color: $color;
            color: $color-invert; } } } } } }

.datepicker-legacy {
  display: none;
  &.is-active {
    display: flex;
    .calendar {
      position: fixed;
      z-index: 40;
      min-width: 20rem;
      max-width: 20rem;
      position: relative;
      margin: 0 auto;
      max-height: calc(100vh - 40px); }
    .calendar-nav {
      flex-direction: column; } }
  @include calendar; }

@include calendar;
